import {
    Box,
    Divider,
    Grid,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Switch,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import OrderServices from "../../../api/orderServices/order.index";
  import { Controller, useForm } from "react-hook-form";
  import { SuccessToaster } from "../../../components/Toaster";
  import { SecondaryButton } from "../../../components/Buttons";
  
  const initialColors = [
    { name: "white", color: "#fff", enabled: true },
  
    { name: "lightgreen", color: "#80C670", enabled: true },
    { name: "purple", color: "#726DE8", enabled: false },
    { name: "darkgreen", color: "#272e26", enabled: false },
    { name: "blue", color: "#194b86", enabled: false },
    { name: "black", color: "#000", enabled: false },
  ];
  
  
  const initialItems = [
    { name: "Standard", value: "" },
    { name: "Premium", value: "" },
    { name: "Half Intensity", value: "" },
    { name: "Half Tone", value: "" },
  ];
  
  const Costings = () => {
    const {
      register,
      handleSubmit,
      formState: { errors },
      control,
      setValue,
      getValues,
    } = useForm();
  
    const [items, setItems] = useState(initialItems);
  
  
    const handleTextChange = (index, event) => {
      const newValue = event.target.value;
      setItems((prevItems) =>
        prevItems.map((item, i) =>
          i === index ? { ...item, value: newValue } : item
        )
      );
    };
  
    const [colors, setColors] = useState(initialColors);
  
    const handleSwitchToggle = (index) => {
      setColors((prevColors) =>
        prevColors.map((item, i) =>
          i === index ? { ...item, enabled: !item.enabled } : item
        )
      );
    };
  
    const [emirates, setEmirates] = useState([]);
    const [standardPrice, setStandardPrice] = useState(0);
    const [orderLimit, setOrderLimit] = useState(0);
  
    const handleChange = (e, item) => {
      console.log(e.target.value);
      console.log(item);
      handleChargeChange(item?._id, e.target.value);
    };
  
    const handleChargeChange = (id, newCharge) => {
      setEmirates((prevEmirates) => {
        const updatedEmirates = prevEmirates.map((item) =>
          item._id === id ? { ...item, charge: newCharge } : item
        );
        console.log(`Updated charge for id ${id}: ${newCharge}`);
        console.log("Updated emirates array:", updatedEmirates);
        return updatedEmirates;
      });
    };
  
    const getPricing = async () => {
      try {
        let params = {};
        const { data } = await OrderServices.getPricing(params);
        console.log(data);
        setEmirates(data?.pricing?.delivery_charges);
        setOrderLimit(data?.pricing?.max_per_order);
        setStandardPrice(data?.pricing?.standard_price);
        setValue("standardPrice", data?.pricing?.standard_price);
        setColors(data?.pricing?.shirt_colors)
        setValue("limit", data?.pricing?.max_per_order);
        setItems([
          { name: "Standard", value: data?.pricing?.standard_price },
          { name: "Premium", value: data?.pricing?.premium_price },
          { name: "Half Intensity", value: data?.pricing?.full_intensity },
          { name: "Half Tone", value: data?.pricing?.halftone },
        ])
      } catch (error) {
        console.log(error);
      } finally {
      }
    };
  
    const updateSettings = async () => {
      console.log(items);
      let shirtObj = {
        standard_price: 0,
        premium_price: 0,
        halftone: 0,
        full_intensity: 0
      }
      for (let i = 0; i < items.length; i++) {
        const element = items[i];
        if (element.name == "Standard") {
          shirtObj.standard_price = element.value;
        }
        else if (element.name == "Premium") {
          shirtObj.premium_price = element.value;
        }
        else if (element.name == "Half Intensity") {
          shirtObj.full_intensity = element.value;
        }
        else if (element.name == "Half Tone") {
          shirtObj.halftone = element.value;
        }
      }
  
      try {
        let obj = {
          max_per_order: getValues("limit"),
          standard_price: getValues("standardPrice"),
          delivery_charges: emirates,
          shirt_colors: colors,
          standard_price: shirtObj.standard_price,
          premium_price: shirtObj.premium_price,
          halftone: shirtObj.halftone,
          full_intensity: shirtObj.full_intensity
        };
  
        const { message } = await OrderServices.updateSettings(obj);
        console.log(message);
        SuccessToaster(message);
      } catch (error) {
        console.log(error);
      } finally {
      }
    };
  
    useEffect(() => {
      getPricing();
    }, []);
    // Initialize form values
    useEffect(() => {
      emirates?.forEach((item) => {
        setValue(`charge${item.id}`, item.charge);
      });
    }, [emirates, setValue]);
  
    return (
      <Box
        sx={{
          mt: 10,
          p: 4,
  
          borderRadius: "8px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
        component="form"
        onSubmit={handleSubmit(updateSettings)}
      >
        <Grid container gap={1} >
          <Grid
            item
            md={5.5}
            sm={12}
            sx={{
              border: "1px solid #e0e0e0",
              borderRadius: "15px",
              p: 3,
              // backgroundColor: "#FFFFFF",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>T-Shirt Pricing</Typography>
            <Divider />
            <List>
              {items.map((item, index) => (
                <ListItem
                  key={index}
                  sx={{ display: "flex", alignItems: "center" }}
                >
  
                  <ListItemText primary={item.name} sx={{ flex: 1 }} />
  
  
                  <TextField
                    variant="outlined"
                    value={item.value}
                    onChange={(event) => handleTextChange(index, event)}
                    placeholder="Enter Price"
                    size="small"
                    sx={{ width: "150px" }}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
         
          <Grid
            item
            md={5.5}
            sm={12}
            sx={{
              border: "1px solid #e0e0e0",
              borderRadius: "15px",
              p: 3,
              // backgroundColor: "#FFFFFF",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>Delivery Charges</Typography>
            <Divider />
            {emirates?.map((item) => (
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                key={item.id}
                sx={{ my: 2 }}
              >
                <Grid item xs={6} sm={6}>
                  <Typography variant="body1" fontWeight="500">
                    {item?.emirate}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <TextField
  
                    id="outlined-start-adornment"
                    fullWidth
                    defaultValue={item.charge}
                    onChange={(e) => handleChange(e, item)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">AED</InputAdornment>
                      ),
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px",
                      },
                      "& label": {
                        fontSize: "0.875rem",
                        color: "#5f6368",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
  
        {/* Submit Button Section */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
          <SecondaryButton
            type="submit"
            sx={{
              backgroundColor: "#1F5591",
              color: "white",
              fontWeight: "bold",
              p: "10px 20px",
              "&:hover": {
                backgroundColor: "#174175",
              },
            }}
            title="Update Costings"
          ></SecondaryButton>
        </Box>
      </Box>
    );
  };
  
  export default Costings;
  